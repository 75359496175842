import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import SpecialCampForm from './SpecialCampForm';
import { specialCampViewModel } from '../../../../components/views/special_camp/special.camp.viewmodel';
import AdminHome from '../../AdminHome';

const initialData = {
  name: '',
  subtitle: '',
  description: '',
  picture: null,
  zoom_link: '',
  created_by: '',
  time_zone: 'America/Los Angeles',
  eventStartDate: '',
  eventStartTime: '',
  eventPublishDate: '',
  eventPublishTime: '',
  camp: [],
  is_root: false, // New state field to track the checkbox
  // free: false,
  freeData: [],
  seedlings: false,
  villagers: true,
  paid: true,
  all: false,
  pay_by: [], // For dropdown
};

const AddSpecialCamp = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState(initialData);
  const [updatefile, setupdatefile] = useState('');
  const [isPublished, setisPublished] = useState(true);
  const addZero = (data) => {
    let tempTime = data;
    if (tempTime < 10) {
      tempTime = '0' + tempTime;
    }
    return tempTime;
  };
  const handleGetAdminSpecialCamp = async (id) => {
    try {
      const data = await specialCampViewModel.getDependentSpecialCamp(id);
      const paymentData = { villager: false, seedling: false, all: false };

      data?.is_free?.forEach((plans) => {
        if (data.is_paid === false) {
          if (plans === 'is_root') {
            paymentData.villager = true;
          } else if (plans === 'seedling') {
            paymentData.seedling = true;
            paymentData.villager = true;
          } else if (plans === 'all') {
            paymentData.villager = true;
            paymentData.seedling = true;
            paymentData.all = true;
          }
        } else {
          if (plans === 'is_root') {
            paymentData.all = true;
            paymentData.villager = true;
            paymentData.seedling = true;
          } else if (plans === 'seedling') {
            paymentData.seedling = true;
            paymentData.all = true;
          } else if (plans === 'all') {
            paymentData.all = true;
          }
        }
      });
      const campList = data?.camp_list.map((item) => item?.id);
      const created_by_id = data?.created_by?.id;
      const now = new Date();

      // Avoid creating Date objects and adjust how you extract date and time:
      let eventPublishDate = data.start_date_time?.slice(0, 10) || ''; // Extract date from start_date_time
      let eventPublishTime = data.start_date_time?.slice(11, 16) || ''; // Extract time from start_date_time

      let eventStartDate = data.end_date_time?.slice(0, 10) || ''; // Extract date from end_date_time
      let eventStartTime = data.end_date_time?.slice(11, 16) || ''; // Extract time from end_date_time

      // Check if backdated and `is_draft` is true (you don't need to use Date objects)
      const isStartDateBackdated = new Date(data.start_date_time) < now;
      const isEndDateBackdated = new Date(data.end_date_time) < now;

      if (data.is_draft) {
        if (isStartDateBackdated) {
          eventPublishDate = '';
          eventPublishTime = '';
        }

        if (isEndDateBackdated) {
          eventStartDate = '';
          eventStartTime = '';
        }
      }

      setupdatefile(data.picture_url);
      setisPublished(data.is_draft);
      setFormData({
        name: data.name || '',
        subtitle: data.subtitle || '',
        description: data.description || '',
        picture: null,
        zoom_link: data.zoom_link || '',
        created_by: created_by_id || '',
        time_zone: data.timezone || 'America/Los Angeles',
        eventPublishDate,
        eventPublishTime,
        eventStartDate,
        eventStartTime,
        camp: campList || [],
        paid: data.is_paid,
        // free: data.is_free,
        freeData: data.is_free,
        seedlings: paymentData.seedling,
        villagers: paymentData.villager,
        pay_by: [], // Ensure it's assigned as an array
        is_root: false,
        all: paymentData.all,
      });
    } catch (error) {
      console.error('Error fetching dream camps:', error);
    }
  };
  useEffect(() => {
    if (id) {
      handleGetAdminSpecialCamp(id);
    } else {
      setFormData(initialData);
      setupdatefile('');
    }
  }, [id]);

  return (
    <AdminHome
      breadcrumData={[
        { title: 'Dream Camps', link: '/r/special-camp-list' },
        { title: 'Add Dream Camp', link: '/r/special-camp-add' },
      ]}
      title={id ? 'Update Dream Camp' : 'Add Dream Camp'}
    >
      <Grid container>
        <Grid item border="1px solid #e3f2fd" sx={{ width: '100%' }}>
          <SpecialCampForm
            id={id}
            initialData={initialData}
            formData={formData}
            setFormData={setFormData}
            updatefile={updatefile}
            setupdatefile={setupdatefile}
            isPublished={isPublished}
          />
        </Grid>
      </Grid>
    </AdminHome>
  );
};

export default AddSpecialCamp;
