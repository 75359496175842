import moment from 'moment';
export const passwordStrengthValidator = (value) =>
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(value);

export const containsNumberOrLetter = (value) => {
  return /^[a-z0-9]+$/i.test(value);
};

export const isKid = (date) => {
  const today = moment();
  const kidBirthdate = moment(date, 'YYYY-M-D');
  const age = today.diff(kidBirthdate, 'years');
  return age <= 16;
};

export const getAge = (date) => {
  const today = moment();
  return today.diff(date, 'years');
};

export const getDirtyValues = (dirtyFields, allValues) => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      getDirtyValues(dirtyFields[key], allValues[key]),
    ])
  );
};
