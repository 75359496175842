import React, { useEffect, useState } from 'react';
import { adminSpecialCampViewModel } from './specialcamp.view.model';
import { adminSpecialCampRepository } from '../../../repository/admin.specialcamp.repository';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import TaletreeLoader from '../../../../components/views/taletree_loader/TaletreeLoader';
import DataTable from '../commonComponent/Table';
import CheckIcon from '@mui/icons-material/Check';
import Chip from '@mui/joy/Chip';
import PaidIcon from '@mui/icons-material/Paid';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { useAuth } from '../../../../components/auth/Auth';
import AdminHome from '../../AdminHome';
import { useParams } from 'react-router-dom';

const columns = [
  { field: 'id', headerName: 'ID', width: 80, withChip: false },
  { field: 'name', headerName: 'Name', width: 150, withChip: false },
  {
    field: 'email',
    headerName: 'Guardian Email',
    width: 250,
    withChip: false,
  },
  {
    field: 'subscriptions',
    headerName: 'Subscriptions',
    width: 140,
    withChip: false,
  },
  {
    field: 'response',
    headerName: 'Response',
    width: 140,
    withChip: false,
  },
];

function DependentWithSpecialCamp() {
  const history = useHistory();
  const { id } = useParams();

  const { showAlert } = useAuth();
  const [limit] = useState(20);
  const [hasMore, setHasMore] = useState(true);
  const [SpecialCampDependents, setSpecialCampDependents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false); // Add error state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [allData, setAllData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [idForColoumnUpdate, setIdForColoumnUpdate] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [availableCamps, setavailableCamps] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true);

  const [query, setQuery] = useState({
    limit: limit,
    has_more: true,
    starting_after: null,
    title: '',
    camp: '',
    status: '',
    paid: '',
  });

  const handleNext = (page) => {
    setCurrentIndex((pre) => pre + 1);
    if (currentIndex < allData.length - 1) {
      const lastItemId =
        SpecialCampDependents[SpecialCampDependents.length - 1]?.id;
      setSpecialCampDependents(allData[currentIndex + 1]);
    } else if (hasMore) {
      handleGetDependen();
    }
  };

  const handleGetDependen = async () => {
    try {
      setIsLoading(true);
      setError(false);
      const apiQuery = {
        ...query,
      };

      if (!query.starting_after) delete apiQuery.starting_after;
      if (query.camp === '') delete apiQuery.camp;
      if (query.paid === '') delete apiQuery.paid;
      if (query.status === '') delete apiQuery.status;
      const response =
        await adminSpecialCampViewModel.getDependentListAttentdWithSpecialCamp(
          id,
          apiQuery
        );
      const row = response?.data.map((item) => ({
        id: item?.id,
        name: `${item?.first_name}  ${item?.last_name}`,
        email: item?.guardian?.email || '',
        subscriptions:
          item?.subscriptions && item?.subscriptions?.subscription_id
            ? `${item?.subscriptions?.nickname} ${
                item?.subscriptions?.interval?.charAt(0).toUpperCase() +
                item?.subscriptions?.interval?.slice(1).toLowerCase()
              }${item?.subscriptions?.nickname ? 'ly' : '-'}`
            : '-',
        response: item?.special_camp?.attend,
      }));

      // setSpecialCampDependents(row);
      setAllData([...allData, [...row]]);
      setSpecialCampDependents(row);
      const totalItems = response?.total || 0; // Assume `response.total` gives total items
      setTotalPages(Math.ceil(totalItems / limit));
      setIdForColoumnUpdate([...idForColoumnUpdate, row[row.length - 1]?.id]);
      if (row.length > 0) {
        const lastItemId = row[row.length - 1]?.id;
        setShouldFetch(false);
        setQuery((prevQuery) => ({
          ...prevQuery,
          starting_after: lastItemId,
          has_more: response?.has_more,
        }));
      }

      setHasMore(response?.has_more);
    } catch (error) {
      console.error('Error fetching dream dependents:', error);
      setHasMore(false);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (shouldFetch) {
      handleGetDependen();
    } else {
      setShouldFetch(true); // Reset fetch flag for next updates
    }
  }, [query]);

  const handlePrevious = () => {
    setCurrentIndex((pre) => pre - 1);
    setSpecialCampDependents(allData[currentIndex - 1]);
  };

  useEffect(() => {
    const handleGetExpertListandCampList = async () => {
      try {
        const schoolList =
          await adminSpecialCampViewModel.getSpeciaCampSchoolList(id);
        const campList = schoolList?.map((item) => ({
          id: item.id,
          name: item?.school_name,
        }));

        setavailableCamps(campList);
      } catch (error) {
        console.log(error, 'errr');
      }
    };
    handleGetExpertListandCampList();
  }, []);
  console.log(allData, 'allData');
  return (
    <AdminHome
      //   route="/r/special-camp-add"
      title="Dependent List"
      breadcrumData={[
        { title: 'Dream Camps', link: '/r/special-camp-list' },
        { title: 'Dependent List' },
      ]}
    >
      <DataTable
        isAction={false}
        columns={columns}
        rows={SpecialCampDependents}
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handleNext}
        handlePrevious={handlePrevious}
        hasMore={hasMore}
        currentIndex={currentIndex}
        disabledNext={hasMore ? false : allData.length - 1 === currentIndex}
        isLoading={isLoading}
        setShouldFetch={setShouldFetch}
        setAllData={setAllData}
        currentRowData={setSpecialCampDependents}
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        setQuery={setQuery}
        query={query}
        availableCamps={availableCamps}
        searchFilter
        campsFilter
        noDataText="Dependents"
        searchLable='Search for dependents'
      />
    </AdminHome>
  );
}

export default DependentWithSpecialCamp;
