import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';
import NotificationImportantRoundedIcon from '@mui/icons-material/NotificationImportantRounded';
import PriceChangeRoundedIcon from '@mui/icons-material/PriceChangeRounded';
import SubscriptionsRoundedIcon from '@mui/icons-material/SubscriptionsRounded';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import { getBaseUrl } from '../../../../utils/config.utils';

export const adminMenuItem = [
  {
    title: 'Camp Captains',
    icon: HomeRoundedIcon,
    link: `${getBaseUrl()}/admin/portal/schoolteacher/`,
  },
  {
    title: 'Camps',
    icon: DashboardRoundedIcon,
    link: `${getBaseUrl()}/admin/portal/school/`,
  },
  {
    title: 'Challenge of the day',
    icon: EmojiEventsRoundedIcon,
    link: `${getBaseUrl()}/admin/portal/challengeoftheday/`,
  },
  {
    title: 'Challenges',
    icon: StarRoundedIcon,
    link: `${getBaseUrl()}/admin/portal/challenge/`,
  },
  {
    title: 'Characters',
    icon: PersonRoundedIcon,
    link: `${getBaseUrl()}/admin/portal/character/`,
  },
  {
    title: 'Comments',
    icon: CommentRoundedIcon,
    link: `${getBaseUrl()}/admin/portal/comment/`,
  },
  {
    title: 'Compliments',
    icon: StarRoundedIcon,
    link: `${getBaseUrl()}/admin/portal/compliment/`,
  },
  {
    title: 'Creation of the day',
    icon: WorkRoundedIcon,
    link: `${getBaseUrl()}/admin/portal/creationoftheday/`,
  },
  {
    title: 'Creations',
    icon: SchoolRoundedIcon,
    link: `${getBaseUrl()}/admin/portal/creation/`,
  },
  {
    title: 'Dependents',
    icon: GroupAddRoundedIcon,
    link: `${getBaseUrl()}/admin/portal/dependent/`,
  },
  {
    title: 'Notifications',
    icon: NotificationImportantRoundedIcon,
    link: `${getBaseUrl()}/admin/portal/notification/`,
  },
  {
    title: 'Prices',
    icon: PriceChangeRoundedIcon,
    link: `${getBaseUrl()}/admin/portal/price/`,
  },
  {
    title: 'Dream camp coupons',
    icon: LocalOfferRoundedIcon,
    link: `${getBaseUrl()}/admin/portal/specialcampcoupon/`,
  },
  {
    title: 'Dream camps',
    icon: DashboardRoundedIcon,
    link: '/r/special-camp-list',
  },
  {
    title: 'Subscriptions',
    icon: SubscriptionsRoundedIcon,
    link: `${getBaseUrl()}/admin/portal/subscription/`,
  },
  {
    title: 'Users',
    icon: GroupRoundedIcon,
    link: `${getBaseUrl()}/admin/portal/user/`,
  },
  // {
  //   title: "Dream Camp",
  //   icon: GroupRoundedIcon,
  //   children: [
  //     {
  //       title: "Add Dream Camp",
  //       link: "ADMIN_ADD_SPECIAL_CAMP",
  //     },
  //     {
  //       title: "Create a new user",
  //       link: null,
  //     },
  //     {
  //       title: "Roles & permission",
  //       link: null,
  //     },
  //   ],
  // }, // this is used for child menu
];
