import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../auth/Auth';
import { LOGIN_ROUTE } from '../../constants/routes.constants';
import { Spinner } from 'react-bootstrap';
import { getBaseUrl } from '../../utils/config.utils';
import { accountRepository } from '../../repository/account.repository';
import { authViewModel } from '../auth/auth.view.model';
import { getSession } from '../../utils/session.utils';

function SuperAdminRoute({ component: Component, ...rest }) {
    const { setSuperAdmin } = useAuth();
    const [isSuperAdmin, setIsSuperAdmin] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const user = await authViewModel.getUser();
                setSuperAdmin(user);

                if (user?.is_superuser) {
                    setIsSuperAdmin(true);
                } else {
                    setIsSuperAdmin(false);
                }
            } catch (error) {
                console.error('Error fetching user or checking super admin status:', error);
                setIsSuperAdmin(false);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUser();
    }, []);


    const redirectToAdminLogin = () => {
        if (isLoading) {
            return null;
        }

        if (isSuperAdmin) {
            return (
                <Route
                    {...rest}
                    render={(props) =>
                        <Component {...props} />
                    }
                />
            );
        } else {
            window.location.replace(`${getBaseUrl()}/admin/portal/`);
            return null;
        }
    }

    return (
        <div>
            {isLoading ? (
                <div>
                    <Spinner
                        style={{ marginTop: '200px' }}
                        key={0}
                        className="home-feed-spinner"
                        animation="border"
                        variant="info"
                    />
                </div>
            ) : (
                redirectToAdminLogin()
            )}
        </div>
    );
}

export default SuperAdminRoute;
