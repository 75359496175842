import React from 'react';
import { Image, Navbar, Nav } from 'react-bootstrap';
import { useAuth } from '../auth/Auth';
import logo from '../../assets/logo/logo-53x53.svg';
import TaletreeHeader from './TaletreeHeader';
import ProfileMenu from './ProfileMenu';

import './css/camp.header.scss';
import { LinkContainer } from 'react-router-bootstrap';
import {
  DASHBOARD_ROUTE,
  GUARDIAN_ACCOUNT_DETAILS_ROUTE,
  GUARDIAN_SETTINGS_ROUTE,
  MULTI_LOGIN,
} from '../../constants/routes.constants';
import { useHistory } from 'react-router-dom';
import { multiLoginViewModal } from '../views/multilogin/multilogin.view.model';
import { getBaseUrl } from '../../utils/config.utils';

const DashboardHeader = () => {
  const { logout, guardian, dependent } = useAuth();
  const history = useHistory();
  const handleParentLogin = async () => {
    if (guardian?.id) {
      history.push(MULTI_LOGIN);
    } else {
      try {
        await multiLoginViewModal.multiLoginParent();
        localStorage.setItem('isGuardianFromBrowse', true);
        refreshUserState();
        history.push(DASHBOARD_ROUTE);
      } catch (error) {
        console.log(error, 'error in  parent login ');
      }
    }
  };

  return (
    <TaletreeHeader className="camp-header guardian">
      <div
      style={{cursor:'pointer'}}
        onClick={() =>
          history.push(GUARDIAN_SETTINGS_ROUTE)
        }
      >
        <Navbar.Brand>
          <Image src={logo} className="logo" />
        </Navbar.Brand>
      </div>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-end"
      >
        <Nav className="me-auto align-item-md-center navbar-sub nav-custom">
          <LinkContainer className="mob-menu" to={GUARDIAN_SETTINGS_ROUTE}>
            <Nav.Link
              className={
                history.location.pathname === GUARDIAN_SETTINGS_ROUTE
                  ? 'px-3 camp-header-link guardian-header-active'
                  : 'px-3 camp-header-link'
              }
            >
              <span className="mob-menu">Dashboard</span>
            </Nav.Link>
          </LinkContainer>
          <LinkContainer
            className="mob-menu"
            to={GUARDIAN_ACCOUNT_DETAILS_ROUTE}
          >
            <Nav.Link
              className={
                history.location.pathname === GUARDIAN_ACCOUNT_DETAILS_ROUTE
                  ? 'px-3 camp-header-link guardian-header-active'
                  : 'px-3 camp-header-link'
              }
            >
              <span className="mob-menu">Account details</span>
            </Nav.Link>
          </LinkContainer>
          {dependent?.viewer === 'guardian' || guardian?.viewer == 'guardian' ? <Nav.Link
              className="px-4 camp-header-link guardian logout"
              onClick={() => handleParentLogin()}
            >
              Switch Account
          </Nav.Link> : ''}
          <Nav.Link
            className="px-4 camp-header-link guardian logout"
            onClick={() => logout()}
          >
            Logout
          </Nav.Link>
          <div className="profile-menu-header-container">
            <ProfileMenu />
          </div>
        </Nav>
      </Navbar.Collapse>
    </TaletreeHeader>
  );
};

export default DashboardHeader;
