import React, { useEffect } from "react";
import { getBaseUrl } from "../../utils/config.utils";
import { useAuth } from "../auth/Auth";
import { HOME_FEED, STAGING_LOGIN } from "../../constants/routes.constants";
import HomeRoute from "../routes/HomeRoute";
import { useHistory } from "react-router-dom";

export default function StagingAuth() {
    const BASE_URL = getBaseUrl();
    const history = useHistory();
    const { staging } = useAuth();

    const handleRedirect = () => {
        if (
          BASE_URL === 'https://staging.taletree.com' ||
          BASE_URL === 'http://staging.taletree.com'
          //   BASE_URL === 'http://localhost:8000'
          // BASE_URL === 'http://taletree.io'
        ) {
          let path;
          if (!staging) {
            path = STAGING_LOGIN;
            history.push(path);
          }
        }
    }

    useEffect(() => {
        handleRedirect();
    }, [])

    useEffect(() => {
        handleRedirect();
    }, [staging])

    return (
        <HomeRoute />
    )
}