import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../auth/Auth';
import {
  LOGIN_ROUTE,
  KID_OLD_USER_ROUTE,
} from '../../constants/routes.constants';

function DependentRoute({ component: Component, ...rest }) {
  const { dependent, isLoading } = useAuth();
  const isDeactivated = () => {
    return !dependent.profile.is_activated;
  };
  console.log('error')
  return (
    <div>
      {isLoading ? (
        //todo add spinner
        <div></div>
      ) : (
        <Route
          {...rest}
          render={(props) =>
            dependent ? (
              // isDeactivated() ? (
              //   <Redirect to={KID_OLD_USER_ROUTE} />
              // ) : (
              //   <Component {...props} />
              // )
              <Component {...props} />
            ) : (
              <Redirect to={LOGIN_ROUTE} />
            )
          }
        />
      )}
    </div>
  );
}

export default DependentRoute;
