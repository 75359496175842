import React, { useState } from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import { FormControl, FormLabel, Input } from '@mui/joy';
import { inputAutofillCss } from '../customData/Inputcss';

export default function SendMailModal({ open, onConfirm, onCancel, setEmail, email }) {
    const testEmail = new RegExp("\\w+([-+.']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*") //email regex
    return (
        <Modal open={open} onClose={onCancel}>
            <ModalDialog
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
                layout="center"
            >
                <Typography id="confirm-dialog-title" level="h4">
                    Please Confirm Email
                </Typography>
                <FormControl sx={{ mt: 2 }}>
                    <FormLabel>Your Email:</FormLabel>
                    <Input
                    sx={{...inputAutofillCss}}
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                        required
                    />
                </FormControl>
                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <Button
                        variant="outlined"
                        color="danger"
                        onClick={onCancel}
                    > Cancel
                    </Button>
                    <Button
                        disabled={!testEmail.test(email)}
                        type='submit'
                        variant="outlined"
                        color="success"
                        onClick={onConfirm}
                    >
                        Submit
                    </Button>
                </Stack>
            </ModalDialog>
        </Modal >
    );
}