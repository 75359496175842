import React from 'react';
import { Dropdown, Row, Col, Image } from 'react-bootstrap';
import RoundButton from '../common/RoundButton';
import Avatar from '../common/Avatar';
import { useAuth } from '../auth/Auth';
import { useHistory } from 'react-router-dom';
import { CAMP_ACCOUNT, DASHBOARD_ROUTE, GUARDIAN_ACCOUNT_DETAILS_ROUTE, GUARDIAN_SETTINGS_ROUTE, HOME_FEED, MULTI_LOGIN, MY_ROOM_ROUTE, MY_SCHOOL_ROUTE } from '../../constants/routes.constants';
import { SIMPLE_TYPE } from '../../constants/avatarType.constants';
import veryfiedOrNot from '../../assets/icon/Verified-badge.svg';
import notVerified from '../../assets/icon/Unverified.svg';
import goldTick from '../../assets/icon/gold_tick.svg';
import silverTick from '../../assets/icon/silver_tick.svg';
import greenTick from '../../assets/icon/green_tick.svg';
import Log_Out from '../../assets/images/log-out.svg';

import './css/profile.menu.scss';
import { multiLoginViewModal } from '../views/multilogin/multilogin.view.model';
import { Box } from '@mui/material';

const ProfileMenu = ({ onSettingsClick }) => {
  const { logout, saveDependentUser, dependent, school, partner, guardian, refreshUserState } = useAuth();
  const history = useHistory();
  const onDependentClick = (dependent) => {
    saveDependentUser(dependent);
    history.replace(MY_ROOM_ROUTE);
  };
  const handleParentLogin = async () => {
    if (guardian?.id) {
      history.push(MULTI_LOGIN)
    }
    else {
      try {
        await multiLoginViewModal.multiLoginParent();
        localStorage.setItem('isGuardianFromBrowse', true);
        refreshUserState()
        history.push(MULTI_LOGIN)
      } catch (error) {
        console.log(error, 'error in  parent login ')
      }
    }
  }

  return (
    <Dropdown className="partner-avatar-drop">
      <Dropdown.Toggle
        className={`rounded-pill avatar-button ${partner && 'partner-res-header'}`}
        id="dropdown-basic"
      >
        <Avatar type={SIMPLE_TYPE} />
        {partner ?
          partner.user_type === 'partner' ?
            <span className='varyfied-or-not-partner'>
              <Image src={!partner.is_verified ? notVerified : partner.type_of_organisation === "non profit" ? silverTick : goldTick} alt="" />
            </span>
            : partner?.user_type === 'camp master' ?
              <span style={{ top: '-3px', left: '37px' }} className='varyfied-or-not-partner'>
                <Image src={partner?.is_verified ? veryfiedOrNot : notVerified} alt="" />
              </span>
              : ''
          :
          school ?
            <span className='varyfied-or-not'>
              <Image src={!school.is_verified ? notVerified : school?.user_type === 'school' ? greenTick : veryfiedOrNot} alt="" />
            </span>
            :
            <span className='varyfied-or-not'>
              {dependent && !dependent.profile.is_activated ?
                <Image src={notVerified} alt="" />
                : dependent ? <Image src={veryfiedOrNot} alt="" /> : ''}
            </span>
        }
      </Dropdown.Toggle>
      <Dropdown.Menu className={`${guardian || school || dependent || dependent?.viewer === 'guardian' ? 'profile-menu-header-guardian' : 'profile-menu-header'} dropdown-menu-right`}>
        <Row className="align-items-center profile-menu-item">
          <Col className="profile-menu-action-text">
            {guardian &&
              <div className='profile-menu-item-wrapper'>
                <p className={`link ${history?.location?.pathname === GUARDIAN_SETTINGS_ROUTE ? 'active' : ''}`} onClick={() => history.push(GUARDIAN_SETTINGS_ROUTE)}>
                  <span className='menu-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" version="1.2" id="home"><path d="M12 3s-6.186 5.34-9.643 8.232A1.041 1.041 0 0 0 2 12a1 1 0 0 0 1 1h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a1 1 0 0 0 1-1 .98.98 0 0 0-.383-.768C18.184 8.34 12 3 12 3z"></path></svg>
                  </span>
                  Dashboard</p>
                <p className={`link ${history?.location?.pathname === GUARDIAN_ACCOUNT_DETAILS_ROUTE ? 'active' : ''}`} onClick={() => history.push(GUARDIAN_ACCOUNT_DETAILS_ROUTE)}>
                  <span className='menu-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" id="user"><path d="M12 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0 2a9.985 9.985 0 0 0-8 4 9.985 9.985 0 0 0 8 4 9.985 9.985 0 0 0 8-4 9.985 9.985 0 0 0-8-4z"></path></svg>
                  </span>
                  Account details
                </p>
              </div>
            }
            {school &&
              <div className='profile-menu-item-wrapper'>
                <p className={`link ${history?.location?.pathname === MY_SCHOOL_ROUTE ? 'active' : ''}`} onClick={() => history.push(MY_SCHOOL_ROUTE)}>
                  <span className='menu-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" version="1.2" id="home"><path d="M12 3s-6.186 5.34-9.643 8.232A1.041 1.041 0 0 0 2 12a1 1 0 0 0 1 1h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a1 1 0 0 0 1-1 .98.98 0 0 0-.383-.768C18.184 8.34 12 3 12 3z"></path></svg>
                  </span>
                  Dashboard</p>
                <p className={`link ${history?.location?.pathname === CAMP_ACCOUNT ? 'active' : ''}`} onClick={() => history.push(CAMP_ACCOUNT)}>
                  <span className='menu-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" id="user"><path d="M12 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0 2a9.985 9.985 0 0 0-8 4 9.985 9.985 0 0 0 8 4 9.985 9.985 0 0 0 8-4 9.985 9.985 0 0 0-8-4z"></path></svg>
                  </span>
                  Account details
                </p>
              </div>
            }
            {dependent?.viewer === 'guardian' || guardian?.viewer == 'guardian' ?
              <div className='profile-menu-item-wrapper' style={{ paddingTop: dependent?.viewer === 'guardian' ? '15px' : '0px', }}>
                <p className={`link ${history?.location?.pathname === HOME_FEED ? 'active' : ''}`} onClick={() => handleParentLogin()}>
                  <span className='menu-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" class="bi bi-ubuntu" viewBox="0 0 16 16">
                      <path d="M2.273 9.53a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.547Zm9.467-4.984a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.546M7.4 13.108a5.54 5.54 0 0 1-3.775-2.88 3.27 3.27 0 0 1-1.944.24 7.4 7.4 0 0 0 5.328 4.465c.53.113 1.072.169 1.614.166a3.25 3.25 0 0 1-.666-1.9 6 6 0 0 1-.557-.091m3.828 2.285a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.546m3.163-3.108a7.44 7.44 0 0 0 .373-8.726 3.3 3.3 0 0 1-1.278 1.498 5.57 5.57 0 0 1-.183 5.535 3.26 3.26 0 0 1 1.088 1.693M2.098 3.998a3.3 3.3 0 0 1 1.897.486 5.54 5.54 0 0 1 4.464-2.388c.037-.67.277-1.313.69-1.843a7.47 7.47 0 0 0-7.051 3.745" />
                    </svg>
                  </span>
                  Switch Account
                </p>
              </div> : ''}
            {dependent?.viewer === 'dependent' ?
              <Box display='flex' flexDirection='row' justifyContent='center' pt='24px'>
                <Image
                  className="fit_size_img city-hover-state"
                  src={Log_Out}
                  alt=""
                />
              </Box>
              : ''}
            <RoundButton
              label="Log out"
              type="button"
              className="logout-button"
              onClick={() => logout()}
            />
          </Col>
        </Row>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileMenu;
