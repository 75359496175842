import * as React from 'react';
import { ColorPaletteProp } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Link from '@mui/joy/Link';
import Chip from '@mui/joy/Chip';
// import RowMenu from './RowMenu';  // Assuming RowMenu is a separate component for the row actions

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import BlockIcon from '@mui/icons-material/Block';
import { Divider, Dropdown, FormControl, FormLabel, Input, Menu, MenuButton, MenuItem } from '@mui/joy';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { ArrowDropDownIcon } from '@mui/x-date-pickers/icons';
import SearchIcon from '@mui/icons-material/Search';

type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const actionList = [
  {
    action: 'edit',
    actionName: 'Edit',
    color: 'success',
    status: true,
  },
  {
    action: 'delete',
    actionName: 'Delete',
    color: 'danger',
    status: true,
  },
];

export default function OrderTable({
  columns,
  rows,
  currentPage,
  totalPages,
  handlePageChange,
  multiSelect = false,
  actions = [],
  onEdit,
}) {
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [orderBy, setOrderBy] = React.useState('id');
  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc');
  const [isConfirmBoxOpen, setIsConfirmBoxOpen] = React.useState(false);
  const [currentAction, setCurrentAction] = React.useState('');
  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const [actionMessage, setActionMessage] = React.useState('');
  const [actionListData] = React.useState([...actionList, ...actions]);
  const [email, setEmail] = React.useState('');
  const handleActionClick = (action, row) => {
    if (row) {
      if (action === 'edit') {
        onEdit(row?.id);
      } else {
        setCurrentAction(action);
        setSelectedRowId(row?.id);
        setActionMessage(`${action.toLowerCase()}?`);
        setIsConfirmBoxOpen(true);
      }
    }
  };
  function ActionMenuItem({ row }) {
    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{
            root: { variant: 'plain', color: 'neutral', size: 'sm' },
          }}
        >
          <MoreHorizRoundedIcon />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
          {actionListData.map((actionItem) => (
            <MenuItem
              hidden={
                !row?.status &&
                (actionItem.action === 'sendMail' ||
                  actionItem.action === 'publish')
              }
              key={actionItem.action}
              color={actionItem.color}
              onClick={() => handleActionClick(actionItem?.action, row)}
            >
              {actionItem?.actionName}
            </MenuItem>
          ))}
          <Divider />
        </Menu>
      </Dropdown>
    );
  }
  return (
    <React.Fragment>
            <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          pb: 2,
          display: { xs: 'flex', sm: 'flex' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
        <FormControl sx={{ flex: 1, maxWidth: 300 }} size="sm">
          <FormLabel>Search for dream camp</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
          />
        </FormControl>
        {/* {renderFilters()} */}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: 'none', sm: 'initial' },
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground':
              'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground':
              'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
            <tr>
              {multiSelect && (
                <th
                  style={{
                    width: 48,
                    textAlign: 'center',
                    padding: '12px 6px',
                  }}
                >
                  <Checkbox
                    size="sm"
                    indeterminate={
                      selected.length > 0 && selected.length !== rows.length
                    }
                    checked={selected.length === rows.length}
                    onChange={(event) => {
                      setSelected(
                        event.target.checked ? rows.map((row) => row.id) : []
                      );
                    }}
                    color={
                      selected.length > 0 || selected.length === rows.length
                        ? 'primary'
                        : undefined
                    }
                    sx={{ verticalAlign: 'text-bottom' }}
                  />
                </th>
              )}

              {/* <th style={{ width: 120, padding: '12px 6px' }}>
                <Link
                  underline="none"
                  color="primary"
                  component="button"
                  onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                  sx={{
                    fontWeight: 'lg',
                    '& svg': {
                      transition: '0.2s',
                      transform:
                        order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                    },
                  }}
                >
                  Invoice
                </Link>
              </th> */}
              {columns.map((column, index) => (
                <th
                  key={index}
                  style={{
                    width: column.width,
                    padding: '12px 6px',
                    cursor: 'pointer',
                    color: column.field === 'id' ? '#0c81e7' : '',
                  }}
                  onClick={() => column.field === 'id' && setOrder(order === 'asc' ? 'desc' : 'asc')}
                >
                  {column.headerName}
                  {orderBy === column.field && (
                    <ArrowDropDownIcon
                      style={{
                        transition: 'transform 0.2s ease',
                        transform:
                          order === 'asc' ? 'rotate(180deg)' : 'rotate(0deg)',
                      }}
                    />
                  )}
                </th>
              ))}
              <th style={{ width: 40 }}></th>
            </tr>
          </thead>
          <tbody>
            {[...rows].sort(getComparator(order, 'id')).map((row) => (
              <tr key={row.id}>
                {/* Checkbox column */}
                {multiSelect && (
                  <td style={{ textAlign: 'center', width: 120 }}>
                    <Checkbox
                      size="sm"
                      checked={selected.includes(row.id)}
                      onChange={() => {
                        setSelected((prevSelected) =>
                          prevSelected.includes(row.id)
                            ? prevSelected.filter((id) => id !== row.id)
                            : [...prevSelected, row.id]
                        );
                      }}
                      sx={{ verticalAlign: 'text-bottom' }}
                    />
                  </td>
                )}
                <td>{row.id}</td>
                {columns
                  .filter((col) => col.field !== 'id')
                  .map((column) => {
                    if (column.withChip) {
                      return (
                        <td key={column.field}>{column.renderChip(row)}</td>
                      );
                    } else {
                      return <td key={column.field}>{row[column.field]}</td>;
                    }
                  })}
                <td>
                  <ActionMenuItem row={row} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>

      <Box
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>

        <Box sx={{ flex: 1 }} />
        {[...Array(totalPages)].map((_, index) => (
          <IconButton
            key={index}
            size="sm"
            variant={currentPage === index + 1 ? 'solid' : 'outlined'}
            color="neutral"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
}
