import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  ADMIN_ADD_SPECIAL_CAMP,
  ADMIN_LIST_EDIT_SPECIAL_CAMP,
  ADMIN_LIST_SPECIAL_CAMP,
  ADMIN_SPECIAL_CAMP,
  DEPENDENT_LIST_WITH_SPECIAL_CAMP,
} from '../constants/routes.constants';
import dashboard from './dashboard/dashboard';
import ListSpecialCamp from './dashboard/components/special_camp/ListSpecialCamp';
import SpecialCampForm from './dashboard/components/special_camp/SpecialCampForm';
import { useAuth } from '../components/auth/Auth';
import AddSpecialCamp from './dashboard/components/special_camp/AddSpecialCamp';
import DependentWithSpecialCamp from './dashboard/components/special_camp/DependentWithSpecialCamp';

const AdminRoutes = () => {
  return (
    <Switch>
      <Route exact path={ADMIN_SPECIAL_CAMP} component={dashboard} />
      <Route exact path={ADMIN_ADD_SPECIAL_CAMP} component={AddSpecialCamp} />
      <Route exact path={ADMIN_LIST_SPECIAL_CAMP} component={ListSpecialCamp} />
      <Route
        exact
        path={DEPENDENT_LIST_WITH_SPECIAL_CAMP}
        component={DependentWithSpecialCamp}
      />

      <Route
        exact
        path={ADMIN_LIST_EDIT_SPECIAL_CAMP}
        component={AddSpecialCamp}
      />
    </Switch>
  );
};

export default AdminRoutes;
