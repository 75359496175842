import React from 'react';
import Lottie from 'react-lottie-player';
import loaderJson from '../../../assets/json/Loader.json';

const TaletreeLoader = () => {
    return (
        <>
            <div className="loader-wrapper">
                <Lottie
                    loop={true}
                    play={true}
                    animationData={loaderJson}
                    className="map-loader-in"
                />
            </div>
        </>
    );
};

export default TaletreeLoader;
