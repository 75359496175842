import {
  guardianLoginErrors,
  kidLoginErrors,
  partnerLoginErrors,
} from '../../constants/error.constants';
import { accountRepository } from '../../repository/account.repository';
import { partnerRepository } from '../../repository/partner.repository';
import { isCampCaptain, isCampMaster, isDependent, isGuardian, isPartner, isSchool } from '../../utils/utils';

function guardianLogin(params) {
  return new Promise((resolve, reject) => {
    accountRepository
      .guardianLogin(params)
      .then((user) => resolve(user.data))
      .catch((error) => {
        if (error.resultCode === 400001) {
          error.message = guardianLoginErrors.other.login;
        }
        reject(error);
      });
  });
}

function dependentLogin(params) {
  return new Promise((resolve, reject) => {
    accountRepository
      .dependentLogin(params)
      .then((user) => resolve(user.data))
      .catch((error) => {
        if (error.resultCode === 400001) {
          error.message = kidLoginErrors.other.login;
        }
        reject(error);
      });
  });
}

function logout() {
  return new Promise((resolve, reject) => {
    accountRepository
      .logout()
      .then(() => resolve())
      .catch((error) => reject(error));
  });
}

function getUser() {
  return new Promise((resolve, reject) => {
    accountRepository
      .getUser()
      .then((user) => {
        let response;
        if (isGuardian(user.data.user_type)) {
          response = getGuardianUser();
        } else if (isDependent(user.data.user_type)){
          response = getDependentUser();
        } else if (isSchool(user.data.user_type)) {
          response = getSchoolUser();
        } else if (isCampCaptain(user.data.user_type)) {
          response = getCampCaptainUser();
        } else if (isPartner(user.data.user_type) || isCampMaster(user.data.user_type)) {
          response = getPartnerUser();
        } else if (!!user.data.is_superuser) {
          response = Promise.resolve(user.data);
        }
        response
          .then((fullUser) => resolve(fullUser))
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });
}

function getGuardianUser() {
  return new Promise((resolve, reject) => {
    accountRepository
      .getGuardianUser()
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
}

function getDependentUser() {
  return new Promise((resolve, reject) => {
    accountRepository
      .getDependentUser()
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
}

function getSchoolUser() {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getSchoolUser()
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
}

function getCampCaptainUser() {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getCampCaptainUser()
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
}

function getPartnerUser() {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getPartnerUser()
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
}

function schoolLogin(params) {
  return new Promise((resolve, reject) => {
    partnerRepository
      .schoolLogin(params)
      .then((user) => resolve(user.data))
      .catch((error) => {
        if (error.resultCode === 400001) {
          error.message = partnerLoginErrors.other.login;
        }
        reject(error);
      });
  });
}

export const authViewModel = {
  logout,
  getUser,
  guardianLogin,
  dependentLogin,
  getDependentUser,
  schoolLogin,
  getSchoolUser,
  getPartnerUser
};
