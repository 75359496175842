import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Spinner } from 'react-bootstrap';
import '../views/account/scss/kid.login.scss';
import { useAuth } from '../auth/Auth';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ValidHttpStatusError from '../api/api.error.valid';
import { displayFormErrorsFromApi } from '../api/api.display.error';
import ResponseErrorNotification from '../api/ResponseErrorNotification';
import { useResponseError } from '../api/response.error';

const LoginStaging = () => {
  const { staging, stagingLogin } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { addError, removeError } = useResponseError();
  const { register, handleSubmit, setError, errors } = useForm({
    mode: 'onBlur',
  });

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // Add your login logic here
    const params = {
      username: email,
      password: password,
    };
    stagingLogin(params)
      .then((res) => {
        // history.push(HOME_FEED)
        history.goBack();
      })
      .catch((error) => {
        if (error instanceof ValidHttpStatusError) {
          displayFormErrorsFromApi(error, setError);
        } else {
          addError(error.message);
        }
        setIsLoading(false);
      });
    // console.log('Login submitted:', { email, password });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (staging) {
      history.goBack();
    }
    setLoading(false);
  }, []);

  return (
    loading ?
      <Spinner
        key={0}
        className="home-feed-spinner"
        animation="border"
        variant="info"
      />
      :
      <Container>
        <h2 className="mt-5">Staging Login</h2>
        <ResponseErrorNotification />
        <Form className="mt-4" onSubmit={onSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </Form.Group>

          <Button className='gred-btn' variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Container>
  );
};

export default LoginStaging;