import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import LogoIcon from '../../../assets/logo/logo-icon.png';
import logoIcon1 from '../../../assets/logo/logo-header.png';
import defaultUser from '../../../assets/profile/default-profile-guardian.svg';

import { SIMPLE_TYPE } from '../../../constants/avatarType.constants';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ColorSchemeToggle from './ColorSchemeToggle';
import { closeSidebar } from '../utils';
import { useHistory } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { useAuth } from '../../../components/auth/Auth';
import { adminMenuItem } from './customData/SideMenuData';
import { useColorScheme } from '@mui/joy';
import logo from '../../../assets/logo/logo-53x53.svg';
import { getBaseUrl } from '../../../utils/config.utils';

function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}) {
  const [open, setOpen] = React.useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={[
          {
            display: 'grid',
            transition: '0.2s ease',
            '& > *': {
              overflow: 'hidden',
            },
          },
          open ? { gridTemplateRows: '1fr' } : { gridTemplateRows: '0fr' },
        ]}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

export default function Sidebar() {
  const history = useHistory();
  const { superAdmin } = useAuth();
  const { mode } = useColorScheme();
  const [selectedItem, setSelectedItem] = React.useState(null);
  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        {/* <IconButton
          variant="soft"
          color="primary"
          sx={{
            background: mode === 'dark' ? '#fff' : '#97C3F0',
            '&:hover': {
              background: mode === 'dark' ? '#fff' : '#97C3F0',
            },
          }}
          size="sm"
        > */}
        {/* <BrightnessAutoRoundedIcon /> */}
        <Image
          onClick={() => {
            window.location.href = `${getBaseUrl()}/admin/portal`;
          }}
          style={{ maxHeight: '32px', maxWidth: '32px', cursor: 'pointer' }}
          src={logo}
        />
        {/* </IconButton> */}
        <Typography level="title-lg">TaleTree</Typography>
        <ColorSchemeToggle sx={{ ml: 'auto' }} />
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        {adminMenuItem.map((menu) => (
          <List
            key={menu.title}
            size="sm"
            sx={{
              gap: 1,
              '--List-nestedInsetStart': '30px',
              '--ListItem-radius': (theme) => theme.vars.radius.sm,
            }}
          >
            {/* Render ListItem for menus without children */}
            {!menu.children && (
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    setSelectedItem(menu.title);
                    menu.link === '/r/special-camp-list'
                      ? history.push(menu.link)
                      : window.location.replace(menu.link);
                  }}
                  selected={menu.title === selectedItem}
                >
                  {menu.icon && <menu.icon />}
                  <ListItemContent>
                    <Typography level="title-sm">{menu.title}</Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            )}

            {/* Render ListItem for menus with children */}
            {menu.children && (
              <ListItem nested>
                <Toggler
                  renderToggle={({ open, setOpen }) => (
                    <ListItemButton onClick={() => setOpen(!open)}>
                      {menu.icon && <menu.icon />}
                      <ListItemContent>
                        <Typography level="title-sm">{menu.title}</Typography>
                      </ListItemContent>
                      <KeyboardArrowDownIcon
                        sx={{
                          transform: open ? 'rotate(180deg)' : 'none',
                        }}
                      />
                    </ListItemButton>
                  )}
                >
                  <List sx={{ gap: 0.5 }}>
                    {menu.children.map((child) => (
                      <ListItem key={child.title}>
                        <ListItemButton component="a" href={child.link || '#'}>
                          {child.title}
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Toggler>
              </ListItem>
            )}
          </List>
        ))}
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Avatar
          variant="outlined"
          size="sm"
          src={superAdmin?.picture_url ? superAdmin?.picture_url : defaultUser}
        />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">{superAdmin?.first_name}</Typography>
          {/* <Typography level="body-xs">siriwatk@test.com</Typography> */}
        </Box>
        {/* <IconButton size="sm" variant="plain" color="neutral">
          <LogoutRoundedIcon />
        </IconButton> */}
      </Box>
    </Sheet>
  );
}
