import ValidHttpStatusError from './api.error.valid';

/**
 * Display errors that utitlize react hook form library
 * @param {ValidHttpStatusError} error response from API
 * @param {*} setError function from react hook form
 */
export const displayFormErrorsFromApi = (error, setError) => {
  setError(error.fieldName, {
    type: 'manual',
    message: error.message,
  });
};

/**
 * Display errors that utitlize react hook form library filtered by value
 * @param {ValidHttpStatusError} error response from API
 * @param {*} setError function from react hook form
 */
export const displayNestedFormErrorsFromApi = (error, setError, formData) => {
  Object.keys(formData).forEach((key) => {
    formData[key].forEach((user, index) => {
      if (error.values.includes(user[error.fieldName])) {
        setError(`${key}[${index}].${error.fieldName}`, {
          type: 'manual',
          message: error.message,
        });
      }
    });
  });
};

/**
 *
 * @param {ValidHttpStatusError} error
 * @param {*} setError
 * @param {*} formData
 */
export const displayErrorFavorites = (error, setError, formData) => {
  Object.keys(formData.favorites).forEach((key) => {
    if (error.values.includes(formData.favorites[key])) {
      setError(`favorites[${key}]`, {
        type: 'manual',
        message: error.message,
      });
    }
  });
};
