export const HOWS_YOUR_DAY = '1';
export const AWAY_PROMPT = '2';
export const EXPLORE_NEW_CHALLENGE_PROMPT = '3';
export const ACCEPT_CHALLENGE_PROMPT = '4';
export const ACCEPTED_CHALLENGE_PROMPT = '5';
export const NEW_CHALLENGE_PROMPT = '6';
export const ACCEPTED_CHALLENGE_ONE_DAY_TO_GO = '7';
export const NEW_CREATION_UPLOAD_PROMPT = '8';
export const UPCOMING_CAMP_PROMPT = '9';
export const UPCOMING_CAMP_30MINS_PROMPT = 10;
export const CAMP_CAPTAIN_IS_ONLINE = 11;
export const DAILY_CHECKINS_REPLY = 12;
export const DEPENDENT_CREATION_OF_THE_DAY = 13;
