import * as React from 'react';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import {
  Divider,
  Dropdown,
  FormControl,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  Select,
  Box,
  Link,
} from '@mui/joy';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { ArrowDropDownIcon } from '@mui/x-date-pickers/icons';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from '../../../../components/auth/Auth';
import TableCardDetail from './TableCardDetail';
import SendMailModal from './SendMailModal';
import ConfirmBox from '../../../../components/common/ConfirmBox';
import { adminSpecialCampViewModel } from '../special_camp/specialcamp.view.model';
import Option from '@mui/joy/Option';
import { CircularProgress } from '@mui/material';
import TaletreeLoader from '../../../../components/views/taletree_loader/TaletreeLoader';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const actionList = [
  {
    action: 'edit',
    actionName: 'Edit',
    color: 'success',
    status: true,
  },
  {
    action: 'delete',
    actionName: 'Delete',
    color: 'danger',
    status: true,
  },
];
const uniqueKey = uuidv4();
export default function DataTable({
  columns,
  rows,
  onEdit,
  onDelete,
  actions = [],
  searchFilter = false,
  paidFilter = false,
  statusFilter = false,
  campsFilter = false,
  currentPage,
  totalPages,
  handlePageChange,
  hasMore,
  multiSelect = false,
  handlePrevious,
  currentIndex,
  disabledNext = false,
  setShouldFetch,
  isLoading,
  setAllData,
  currentRowData,
  setSearchQuery,
  searchQuery,
  setQuery,
  query,
  availableCamps,
  isAction = true,
  noDataText = 'data found',
  isNeedPagination = true,
  searchLable,
}) {
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [isConfirmBoxOpen, setIsConfirmBoxOpen] = React.useState(false);
  const [currentAction, setCurrentAction] = React.useState('');
  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const [actionMessage, setActionMessage] = React.useState('');
  const [actionListData] = React.useState([...actionList, ...actions]);
  const [email, setEmail] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('id');
  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc');
  const [filterByStatus, setFilterByStatus] = React.useState('');
  const [filterByCamps, setFilterByCamps] = React.useState([]);
  const [filterByPaid, setFilterByPaid] = React.useState('');
  const queryData = { limit: 20, has_more: true, starting_after: 0 };
  const { showAlert } = useAuth();
  const history = useHistory();
  const handleActionClick = (action, row) => {
    if (row) {
      if (action === 'dependentList') {
        history.push(`/r/dependent-list/${row?.id}`);
      }
      if (action === 'edit') {
        onEdit(row?.id);
      } else {
        setCurrentAction(action);
        setSelectedRowId(row?.id);
        setActionMessage(`${action.toLowerCase()}?`);
        setIsConfirmBoxOpen(true);
      }
    }
  };
  const renderFilters = () => (
    <React.Fragment>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}
      >
        {statusFilter && (
          <FormControl size="sm" sx={{ width: '122px' }}>
            <FormLabel sx={{ fontSize: { xs: '10px', sm: '12px' } }}>
              Filter by status
            </FormLabel>
            <Select
              defaultValue=""
              value={filterByStatus}
              name="filterByStatus"
              onChange={(e, value) => {
                if (value === 'unpublised' || value === 'published') {
                  setQuery({
                    ...query,
                    ...queryData,
                    // title: '',
                    status: value === 'unpublised' ? true : false,
                  });
                  // setFilterByCamps([]);
                  // setFilterByPaid('');
                  setFilterByStatus(value);
                } else {
                  setQuery({
                    ...query,
                    ...queryData,
                    status: '',
                    // title: '',
                  });
                  setFilterByStatus('');
                }
                setAllData([]);
              }}
              size="sm"
              placeholder="Filter by status"
              slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
            >
              <Option value="">All</Option>
              <Option value="published">Published</Option>
              <Option value="unpublised">UnPublished</Option>
            </Select>
          </FormControl>
        )}

        {paidFilter && (
          <FormControl size="sm" sx={{ width: '75px' }}>
            <FormLabel sx={{ fontSize: { xs: '10px', sm: '12px' } }}>
              Filter by paid
            </FormLabel>
            <Select
              defaultValue=""
              size="sm"
              value={filterByPaid}
              name="filterByPaid"
              onChange={(e, value) => {
                if (value === 'true' || value === 'false') {
                  setQuery({
                    ...query,
                    ...queryData,
                    // title: '',
                    paid: value === 'true' ? true : false,
                  });
                  // setFilterByCamps([]);
                  // setFilterByStatus('');
                  setFilterByPaid(value);
                } else {
                  setQuery({
                    ...query,
                    ...queryData,
                    // title: '',
                    paid: '',
                  });
                  setFilterByPaid('');
                }
                setAllData([]);
              }}
            >
              <Option value="">All</Option>
              <Option value="true">Paid</Option>
              <Option value="false">Free</Option>
            </Select>
          </FormControl>
        )}
        {campsFilter && (
          <FormControl size="sm" sx={{ width: '200px' }}>
            <FormLabel sx={{ fontSize: { xs: '10px', sm: '12px' } }}>
              Filter By Camps
            </FormLabel>
            <Select
              // disabled={!isPublished}
              defaultValue={[]}
              name="filterByCamps"
              value={filterByCamps.reverse()}
              placeholder="All camps"
              onChange={(e, value) => {
                const data = value.join(',') + ',';
                console.log(data);
                if (value.length) {
                  setQuery({
                    ...query,
                    ...queryData,
                    // title: '',
                    camp: decodeURIComponent(data),
                  });
                  // setFilterByPaid('');
                  // setFilterByStatus('');
                  setFilterByCamps(value);
                } else {
                  setQuery({
                    // limit: 20,
                    // has_more: true,
                    // starting_after: null,
                    ...query,
                    ...queryData,
                    camp: '',
                    title: '',
                  });
                  setFilterByCamps([]);
                }
                setAllData([]);
              }}
              multiple
            >
              {availableCamps?.map(
                (camp, index) =>
                  camp.name && (
                    <Option key={index} value={camp?.id}>
                      {camp.name}
                    </Option>
                  )
              )}
            </Select>
          </FormControl>
        )}
      </Box>
    </React.Fragment>
  );
  function ActionMenuItem({ row }) {
    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{
            root: { variant: 'plain', color: 'neutral', size: 'sm' },
          }}
        >
          <MoreHorizRoundedIcon />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
          {actionListData.map((actionItem) => {
            const actionName =
              actionItem.action === 'edit' && !row?.status
                ? 'View'
                : actionItem?.actionName;
            return (
              <MenuItem
                hidden={
                  !row?.status &&
                  (actionItem.action === 'sendMail' ||
                    actionItem.action === 'publish')
                }
                key={actionItem.action}
                color={actionItem.color}
                onClick={() => handleActionClick(actionItem?.action, row)}
              >
                {actionName}
              </MenuItem>
            );
          })}
          <Divider />
        </Menu>
      </Dropdown>
    );
  }
  const campListIdArray = (row) => row.camp.map((item) => item.id);

  const onSendMail = async (id) => {
    if (!id && email) {
      console.log('email data not found table row');
      return;
    }
    const row = rows.find((item) => item.id === id);

    if (!row) {
      console.log(`No row found for id: ${id}`);
      return;
    }
    const data = {
      email: email,
      name: row.name,
      expert: row.expert || '',
      date: row.date || '',
      image_url: row.image,
      campList: row?.camp.length ? campListIdArray(row) : [],
    };

    {
      try {
        const response =
          await adminSpecialCampViewModel.sendMailAdminSpecialCamp(data);
        if (response) {
          showAlert('Successfully send mail', true);
        }
      } catch (error) {
        console.error('Error sending mail for dream camp:', error);
        showAlert(error?.serverResponse.error_info, false);
      }
    }
    setEmail('');
  };

  const onPublishSpecialCamp = async (id) => {
    try {
      const response = await adminSpecialCampViewModel.publishAdminSpecialCamp(
        id
      );
      if (response) {
        showAlert('Successfully published', true);

        // Update the specific row's `status` in `allData`
        setAllData((prevData) =>
          prevData.map((innerArray) =>
            innerArray.map((item) =>
              item.id === id ? { ...item, status: false } : item
            )
          )
        );

        // Update the `status` key in `specialCamp` (array of objects)
        currentRowData((prevCamp) =>
          prevCamp.map((item) =>
            item.id === id ? { ...item, status: false } : item
          )
        );
      }
    } catch (error) {
      console.error('Error to publish dream camp:', error);
      showAlert(
        error?.serverResponse?.error_info ||
          'Failed to publish camp, try again or update details',
        false
      );
    }
  };

  const handleConfirmAction = () => {
    if (currentAction === 'delete') {
      onDelete(selectedRowId);
    } else if (currentAction === 'publish') {
      onPublishSpecialCamp(selectedRowId);
    } else if (currentAction === 'sendMail') {
      onSendMail(selectedRowId);
    }
    setIsConfirmBoxOpen(false); // Close the confirm box after action
  };
  const handleCancelAction = () => {
    setIsConfirmBoxOpen(false); // Close the confirm box without any action
  };

  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          pb: 2,
          display: { xs: 'flex', sm: 'flex' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
        {searchFilter && (
          <FormControl sx={{ flex: 1, maxWidth: 300 }} size="sm">
            <FormLabel sx={{ fontSize: { xs: '10px', sm: '12px' } }}>
              {searchLable}
            </FormLabel>
            <Input
              size="sm"
              name="searcQuery"
              value={query?.title}
              placeholder="Search"
              onChange={(e) => {
                if (e.target.value) {
                  setQuery({
                    ...query,
                    ...queryData,
                    title: e.target.value,
                    starting_after: null,
                  });
                  setShouldFetch(true);
                } else {
                  setQuery({
                    ...query,
                    ...queryData,
                    starting_after: null,
                    title: '',
                  });
                  setShouldFetch(true);
                }
                setAllData([]);
                setSearchQuery(e.target.value);
              }}
              startDecorator={<SearchIcon />}
            />
          </FormControl>
        )}
        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: 'none', sm: 'initial' },
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        {isLoading ? (
          <TaletreeLoader />
        ) : (
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              '--TableCell-headBackground':
                'var(--joy-palette-background-level1)',
              '--Table-headerUnderlineThickness': '1px',
              '--TableRow-hoverBackground':
                'var(--joy-palette-background-level1)',
              '--TableCell-paddingY': '4px',
              '--TableCell-paddingX': '8px',
            }}
          >
            <thead>
              <tr>
                {multiSelect && (
                  <th
                    style={{
                      width: 48,
                      textAlign: 'center',
                      padding: '12px 6px',
                    }}
                  >
                    <Checkbox
                      size="sm"
                      indeterminate={
                        selected.length > 0 && selected.length !== rows.length
                      }
                      checked={selected.length === rows.length}
                      onChange={(event) => {
                        setSelected(
                          event.target.checked ? rows.map((row) => row.id) : []
                        );
                      }}
                      color={
                        selected.length > 0 || selected.length === rows.length
                          ? 'primary'
                          : undefined
                      }
                      sx={{ verticalAlign: 'text-bottom' }}
                    />
                  </th>
                )}
                {columns.map((column, index) => (
                  <th
                    key={index}
                    style={{
                      width: column.width,
                      padding: '12px 6px',
                      cursor: 'pointer',
                      color: column.field === 'id' ? '#0c81e7' : '',
                    }}
                    onClick={() =>
                      column.field === 'id' &&
                      setOrder(order === 'asc' ? 'desc' : 'asc')
                    }
                  >
                    {column.headerName}
                    {orderBy === column.field && (
                      <ArrowDropDownIcon
                        style={{
                          transition: 'transform 0.2s ease',
                          transform:
                            order === 'asc' ? 'rotate(180deg)' : 'rotate(0deg)',
                        }}
                      />
                    )}
                  </th>
                ))}
                {isAction && <th style={{ width: 66,padding: '12px 6px', }}>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {rows?.length
                ? [...rows].sort(getComparator(order, 'id')).map((row) => (
                    <tr key={row.id}>
                      {/* Checkbox column */}
                      {multiSelect && (
                        <td
                          style={{ textAlign: 'center', width: 120 }}
                          key={`checkbox-${row.id}`}
                        >
                          <Checkbox
                            size="sm"
                            checked={selected.includes(row.id)}
                            onChange={() => {
                              setSelected((prevSelected) =>
                                prevSelected.includes(row.id)
                                  ? prevSelected.filter((id) => id !== row.id)
                                  : [...prevSelected, row.id]
                              );
                            }}
                            sx={{ verticalAlign: 'text-bottom' }}
                          />
                        </td>
                      )}
                      {/* Row ID column */}
                      <td key={`id-${row.id}`}>
                        <Typography level="body-sm">{row.id}</Typography>
                      </td>
                      {/* Columns */}
                      {columns
                        .filter((col) => col.field !== 'id')
                        .map((column) => {
                          const cellKey = `${column.field}-${row.id}`; // Combine field and row.id for uniqueness
                          if (column.withChip) {
                            return (
                              <td key={cellKey}>{column.renderChip(row)}</td>
                            );
                          } else {
                            return (
                              <td key={cellKey}>
                                <Typography level="body-sm">
                                  {row[column.field]} &nbsp;
                                  {column.field === 'dependentCount' ? (
                                    <Typography
                                      component="div"
                                      onClick={() => {
                                        if (
                                          row[column.field] !== 0 &&
                                          row[column.field] !== '0'
                                        ) {
                                          handleActionClick(
                                            'dependentList',
                                            row
                                          );
                                        }
                                      }}
                                      sx={{
                                        color: 'rgb(11,107,203) !important',
                                        cursor: 'pointer',
                                      }}
                                      // href={`/dependents/${row.id}`}

                                      style={{
                                        textDecoration: 'none',
                                        color: 'blue',
                                      }}
                                    >
                                      View
                                    </Typography>
                                  ) : (
                                    ''
                                  )}
                                </Typography>
                              </td>
                            );
                          }
                        })}
                      {/* Action column */}
                      {isAction && (
                        <td key={`action-${row.id}`}>
                          <ActionMenuItem row={row} />
                        </td>
                      )}
                    </tr>
                  ))
                : ''}
            </tbody>
          </Table>
        )}
      </Sheet>
      {!rows.length && !isLoading ? (
        <div
          className="not_found_sec"
          style={{ textAlign: 'center', padding: '20px 0px' }}
        >
          0 {noDataText}
        </div>
      ) : (
        ''
      )}
      <TableCardDetail
        columns={columns}
        rows={rows}
        actions={actionListData}
        handlePrevious={handlePrevious}
        handlePageChange={handlePageChange}
        hasMore={hasMore}
        handleActionClick={handleActionClick}
        multiSelect={multiSelect}
        currentIndex={currentIndex}
        currentPage={currentPage}
        isLoading={isLoading}
        disabledNext={disabledNext}
        searchQuery={searchQuery}
        isNeedPagination={isNeedPagination}
      />

      {isNeedPagination && (
        <Box
          className="Pagination-laptopUp"
          sx={{
            pt: 2,
            gap: 1,
            [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
            display: {
              xs: 'none',
              sm: 'flex',
            },
          }}
        >
          <Box sx={{ m: 1 }}>
            <Button
              size="sm"
              variant="outlined"
              color="neutral"
              startDecorator={<KeyboardArrowLeftIcon />}
              onClick={() => handlePrevious()}
              disabled={currentIndex === 0 || !rows.length}
            >
              Previous
            </Button>
          </Box>
          <Box sx={{ flex: 1 }} />
          {[...Array(totalPages)].map((_, index) => (
            <IconButton
              hidden
              key={index}
              size="sm"
              variant={currentPage === index + 1 ? 'solid' : 'outlined'}
              color="neutral"
              onClick={() => handlePageChange(index + 1)}
            >
              {/* {index + 1} */}
            </IconButton>
          ))}
          <Box sx={{ flex: 1 }} />
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              size="sm"
              variant="outlined"
              color="neutral"
              endDecorator={<KeyboardArrowRightIcon />}
              disabled={isLoading || disabledNext || !rows.length}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </Button>
            {isLoading && (
              <CircularProgress
                size={24}
                color="success"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </Box>
      )}
      {currentAction === 'sendMail' ? (
        <SendMailModal
          open={isConfirmBoxOpen}
          onCancel={handleCancelAction}
          onConfirm={handleConfirmAction}
          setEmail={setEmail}
          email={email}
        />
      ) : (
        <ConfirmBox
          open={isConfirmBoxOpen}
          actionMessage={actionMessage}
          onConfirm={handleConfirmAction}
          onCancel={handleCancelAction}
        />
      )}
    </React.Fragment>
  );
}
