import { getAdminAPIUrl } from '../../utils/config.utils';
import { fetchUtils } from '../../utils/fetch.utils';

const BASE_URL = getAdminAPIUrl();
const ADMIN = 'admin';
const SPECIALCAMP = 'special-camps';

const addAdminSpecialCamp = (params) => {
  return fetchUtils.post(`${BASE_URL}/${ADMIN}/${SPECIALCAMP}/add`, params);
};
const AddImageSpecialCamp = (special_camp_id, params) => {
  return fetchUtils.postMultiForm(
    `${BASE_URL}/${ADMIN}/${SPECIALCAMP}/upload-special-camp-image/${special_camp_id}`,
    params
  );
};

const getAdminSpecialCamps = (params) => {
  const query = fetchUtils.buildQuery(params); // Build the query string from the params
  return fetchUtils.get(
    `${BASE_URL}/${ADMIN}/${SPECIALCAMP}/get-list?${query}`
  );
};

const getExpertList = () => {
  return fetchUtils.get(`${BASE_URL}/${ADMIN}/${SPECIALCAMP}/get-expert-list`);
};
const getCampList = (camp_id) => {
  let url = `${BASE_URL}/${ADMIN}/${SPECIALCAMP}/get-school-list`;

  // Append the query parameter only if camp_id is defined
  if (camp_id !== undefined) {
    url += `?special_camp=${camp_id}`;
  }

  return fetchUtils.get(url);
};
const deleteAdminSpecialCamps = (id) => {
  return fetchUtils.requestDelete(
    `${BASE_URL}/${ADMIN}/${SPECIALCAMP}/delete/${id}`
  );
};
const updateAdminSpecialCamps = (data) => {
  return fetchUtils.patch(`${BASE_URL}/${ADMIN}/${SPECIALCAMP}/update`, data);
};

const sendEmailAdminSpecialCamp = (data) => {
  return fetchUtils.post(
    `${BASE_URL}/${ADMIN}/${SPECIALCAMP}/api/submit-email`,
    data
  );
};
const publishAdminSpecialCamp = (id) => {
  return fetchUtils.post(
    `${BASE_URL}/${ADMIN}/${SPECIALCAMP}/publish-special-camp/${id}`
  );
};
const getDependentListAttentdWithSpecialCamp = (dependentId, searchQuery) => {
  const query = fetchUtils.buildQuery(searchQuery);
  return fetchUtils.get(
    `${BASE_URL}/${ADMIN}/${SPECIALCAMP}/acepted-dependent/${dependentId}?${query}`
  );
};
export const adminSpecialCampRepository = {
  addAdminSpecialCamp,
  getAdminSpecialCamps,
  deleteAdminSpecialCamps,
  updateAdminSpecialCamps,
  getExpertList,
  getCampList,
  sendEmailAdminSpecialCamp,
  publishAdminSpecialCamp,
  AddImageSpecialCamp,
  getDependentListAttentdWithSpecialCamp,
};
