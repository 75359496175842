import React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';

export default function ConfirmBox({ open, actionMessage, onConfirm, onCancel }) {
    return (
        <Modal open={open} onClose={onCancel}>
            <ModalDialog
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
                layout="center"
            >
                <Typography id="confirm-dialog-title" level="h4">
                    Please Confirm...
                </Typography>
                <Typography textAlign='center' id="confirm-dialog-description" mt={2} mb={2}>
                    {`Are you sure to ${actionMessage}`}
                </Typography>
                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <Button
                        variant="outlined"
                        color="danger"
                        onClick={onCancel}
                    >
                        No
                    </Button>
                    <Button
                        variant="outlined"
                        color="success"
                        onClick={onConfirm}
                    >
                        Yes
                    </Button>
                </Stack>
            </ModalDialog>
        </Modal>
    );
}
