import { Alert, Box, Snackbar } from '@mui/material';
import Slide from '@mui/material/Slide';
import React from 'react';
import PropTypes from 'prop-types';

function ToasterAlert({ alertMessages }) {
  const [state] = React.useState({
    Transition: Slide,
  });
  return alertMessages ? (
    <Box>
      {alertMessages.map(({ type, message }, index) => (
        <Snackbar
          key={`${message}+key`}
          open={!!message}
          autoHideDuration={2000}
          TransitionComponent={state.Transition}
          // onClose={handleAlertClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          style={{ marginBottom: index * 60, zIndex: '100000' }}
        >
          <Alert
            sx={{
              alignItems: 'center',
              fontSize: '12px',
              fontWeight: '600',
              width: '100%',
              maxWidth: '300px',
            }}
            // onClose={() => {
            //   handleAlertClose([]);
            // }}
            severity={type ? 'success' : 'error'}
            variant="filled"
          >
            {message}
          </Alert>
        </Snackbar>
      ))}
    </Box>
  ) : (
    <></>
  );
}
ToasterAlert.propTypes = {
  alertMessages: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.bool.isRequired,
      message: PropTypes.string,
    })
  ).isRequired,
  // handleAlertClose: PropTypes.func.isRequired,
};

export default ToasterAlert;
