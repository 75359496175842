import * as React from 'react';
import { CssBaseline, Box, CssVarsProvider } from '@mui/joy';
import Button from '@mui/joy/Button';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import Sidebar from './components/Sidebar';
import Header from './components/Header';
import { Add } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { getBaseUrl } from '../../utils/config.utils';

export default function AdminHome({
  children,
  route = '',
  title = '',
  breadcrumData = [],
}) {
  const history = useHistory();

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <Header />
        <Sidebar />
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: 'calc(12px + var(--Header-height))',
              sm: 'calc(12px + var(--Header-height))',
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            overflow: 'auto',
            gap: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<ChevronRightRoundedIcon fontSize="sm" />}
              sx={{ pl: 0 }}
            >
              {/* <Link
                underline="none"
                color="neutral"
                href="#some-link"
                aria-label="Home"
              >
                <HomeRoundedIcon />
              </Link> */}
              <Link
                underline="hover"
                color="neutral"
                href={`${getBaseUrl()}/admin/portal`}
                sx={{ fontSize: 12, fontWeight: 500 }}
              >
                Dashboard
              </Link>
              {breadcrumData &&
                // <Typography
                //   color="primary"
                //   sx={{ fontWeight: 500, fontSize: 12 }}
                // >
                //   {breadCrumTitle}
                // </Typography>
                breadcrumData.map((value, index) => (
                  <Link
                    underline="hover"
                    onClick={() => history.push(value.link)}
                    key={index}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Typography
                      color="primary"
                      sx={{ fontWeight: 500, fontSize: 12 }}
                    >
                      {value.title}
                    </Typography>
                  </Link>
                ))}
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {title && (
              <Typography level="h2" component="h1">
                {title}
              </Typography>
            )}
            {route && (
              <Button
                onClick={() => history.push(route)}
                color="primary"
                startDecorator={<Add />}
                size="sm"
                sx={{ ml: 'auto' }}
              >
                Add
              </Button>
            )}
          </Box>
          {children}
        </Box>
      </Box>
    </CssVarsProvider>
  );
}
