export const getBaseUrl = () => {
  return process.env.TALE_TREE_URL;
  //return 'http://localhost:8080';
};

export const getAPIUrl = () => {
  return `${getBaseUrl()}/api/v2`;
};
export const getAdminAPIUrl = () => {
  return `${getBaseUrl()}/api`
}
export const getAPIUrlV3 = () => {
  return `${getBaseUrl()}/api/v3`;
};

export const getGoogleAnalyticsTrackingId = () => {
  return process.env.GCLOUD_ANALYTICS_ID;
};

export const isDev = () => {
  return process.env.NODE_ENV === 'development';
};

export const getHomePage = () => {
  return process.env.HOME_PAGE;
};

export const getEnvironment = () => {
  return process.env.NODE_ENV;
};

export const getStripeApiKey = () => {
  // return "pk_test_51Lf0IQSFWUPP4orBlC3Dt9zhAZVUkgGsdTThNOXo5OTnVTRgvpQkuuAaa3OGFzhnIlXwk3sE3jxtyTLbQeXNQZ6K0093iMM3iU";
  return process.env.STRIPE_API_KEY;
};

export const getRecaptchaKey = () => {
  return process.env.RECAPTCHA_KEY;
  //return "6LeNPs0hAAAAAKoBehuBO7ojuxbctvOq1RQihQ_d";
};

export const getStagingCredentials = () => {
  const userName = process.env.STAGING_USERNAME
  const password = process.env.STAGING_PASSWORD
  const credential = {
    userName,
    password
  }
  return (
    credential
  )
}

export const getKakaoURL = () => {
  return process.env.KAKAO_URI;
};

export const getKakaoApiKey = () => {
  return process.env.KAKAO_REST_API_KEY;
};

export const getKakaoRedirectURI = () => {
  return process.env.KAKAO_REDIRECT_URI;
};

export const getMicrosoftURL = () => {
  return process.env.MICROSOFT_URI;
};

export const getMicrosoftClientId = () => {
  return process.env.MICROSOFT_CLIENT_ID;
};

export const getGoogleOAuthClientId = () => {
  return process.env.GGOGLE_CLIENT_ID;
}