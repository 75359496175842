import moment from 'moment';
import axios from 'axios';
import { getBaseUrl, getKakaoApiKey, getKakaoRedirectURI, getKakaoURL, getMicrosoftClientId, getMicrosoftURL } from './config.utils';
import { MICROSOFT_CALLBACK_SCREEN } from '../constants/routes.constants';

export const isEqualString = (str1, str2) => {
  return str1.localeCompare(str2) === 0;
};

export const isGuardian = (userType) => {
  return isEqualString(userType, 'guardian');
};

export const isDependent = (userType) => {
  return isEqualString(userType, 'dependent');
};

export const isSchool = (userType) => {
  return isEqualString(userType, 'school');
};

export const isCampCaptain = (userType) => {
  return isEqualString(userType, 'school_teacher');
};

export const isPartner = (userType) => {
  return isEqualString(userType, 'partner');
};

export const isCampMaster = (userType) => {
  return isEqualString(userType, 'camp master');
};

export const isObjEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const isStrNullOrEmpty = (s) => {
  return !s || s.length === 0;
};

export const emptyPromise = (val = null) => {
  return new Promise((resolve) => {
    resolve(val);
  });
};

export const ampmFormat = (str) => {
  let hours = str.substring(0, 2);
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  return hours + ampm;
};

export const priceWithDollerSign = (num, nagativeSign) => {
  const sign = nagativeSign ? nagativeSign : '';
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  const target = formatter.format(num / 100);
  const index = target.indexOf('.');
  return sign + target;
  // return sign + target.substr(0, index); // $30 $3,000
};

export const getMonToSunDate = (isNextWeek) => {
  isNextWeek = isNextWeek || false;
  const Monday = !isNextWeek
    ? moment().isoWeekday(1).tz('America/Los_Angeles')
    : moment().add(1, 'weeks').isoWeekday(1).tz('America/Los_Angeles');
  const Sunday = !isNextWeek
    ? moment().isoWeekday(7).tz('America/Los_Angeles')
    : moment().add(1, 'weeks').isoWeekday(7).tz('America/Los_Angeles');
  return Monday.format('MM/DD') + ' - ' + Sunday.format('MM/DD');
};

export const isStartInNextWeek = (challenge) => {
  const startDate = moment(challenge.start_date_time, moment.ISO_8601);
  const monday = moment()
    .add(1, 'weeks')
    .isoWeekday(1)
    .tz('America/Los_Angeles');
  const sunday = moment()
    .add(1, 'weeks')
    .isoWeekday(7)
    .tz('America/Los_Angeles');
  return (
    startDate.isSameOrAfter(monday, 'day') &&
    startDate.isSameOrBefore(sunday, 'day')
  );
};

export const properize = (str) => {
  return `${str}${str.length - 1 === str.lastIndexOf('s') ? "'" : "'s"}`;
};

export const shallowEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) return false;
  }

  return true;
};

export const changeBodyClassName = (classes) => {
  const body = document.body;
  body.classList.remove(...body.classList);
  classes.map((name) => {
    body.classList.add(name);
  });
};

export const convertStripeDate = (str) => {
  return moment(str * 1000)
    .format()
    .split('T')[0];
};

export const addMyRoomClassName = (classes) => {
  const body = document.getElementById('myroom-theme');
  body.classList.remove(...body.classList);
  // body.classList.add('flex-wrapper');
  classes.map((name) => {
    body.classList.add(name);
  });
};

export const agoraChatGroupName = (title) => {
  switch (window.location.hostname) {
    case "localhost":
      return `local_${title}`
    case "staging.taletree.com":
      return `staging_${title}`
    case "taletree.com":
      return `prod_${title}`
    default:
      return title
  }
}

export const checkEmailValidation = (email) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

export const kakaoLoginURL = () => {
  return `${getKakaoURL()}?response_type=code&client_id=${getKakaoApiKey()}&redirect_uri=${getBaseUrl() + getKakaoRedirectURI()}`
}

export const getGeolocationData = async () => {
  try {
    const response = await axios.get('https://ipapi.co/json/');
    return response.data;
  } catch (error) {
    console.error('Error fetching geolocation data:', error);
    return null;
  }
};

export const microsoftLoginURL = () => {
  return `${getMicrosoftURL()}?response_type=code&client_id=${getMicrosoftClientId()}&redirect_uri=${getBaseUrl() + MICROSOFT_CALLBACK_SCREEN}&response_mode=query&scope=https://graph.microsoft.com/User.Read`
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = String(date.getUTCFullYear()).slice(-2); // Get last two digits of the year

  return `${day}/${month}/${year}`;
};

export const getUserTypeBadge = (userType) => {
  const getType = () => {
    switch (userType) {
      case "school_teacher":
        return "Captain";
      case "school":
        return "Master";
      case "camp master":
        return "Expert";
      case "partner":
        return "Partner";
      default:
        break;
    }
  }

  return getType()
}

export const isPayByCamp = (pay_by) => {
  const getStatus = () => {
    switch (pay_by) {
      case "by_camp":
        return true
      case "by_sponsorship":
        return true
      default:
        return false
    }
  }

  return getStatus()
}

export const getCompanionColor = (companion) => {
  const getColor = () => {
    switch (companion) {
      case 'letsgo':
        return '#30D884'
      case 'uni':
        return '#F78BC9'
      case 'rushmore':
        return '#6195F2'
      case 'cody':
        return '#FF6A57'
      case 'doma':
        return '#62E8EF'
      case 'rooty':
        return '#F7BA4D'
      default:
        break;
    }
  }
  return getColor();
}

export const handleScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth', // for smooth scrolling
  });
};

export const customStyles = {
  control: (base) => ({
    ...base,
    height: 'auto',
    color: '#321b6d',
    fontWeight: '400',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#321b6d',
  }),
  menu: (provided) => ({
    ...provided,
  }),
};

export const getDependentSubscriptionBadge = (tag) => {
  if (tag?.is_verified) {
    if (
      tag?.subscriptions?.nickname !== 'Villagers' &&
      tag?.subscriptions?.nickname !== 'Branches' &&
      tag?.profile?.tribe_request_status === 'accept' &&
      isPayByCamp(tag?.school_details?.pay_by)
    ) {
      return 'S';
    } else {
      if (tag?.subscriptions?.subscription_id) {
        switch (tag?.subscriptions?.nickname) {
          case 'Villagers':
            return 'V';
          case 'Branches':
            return 'V';
          case 'Seedlings V2':
            return 'S';
          case 'Roots':
            return 'S';
          case 'Seedlings':
            return 'S';
          default:
            break;
        }
      } else {
        return 'E';
      }
    }
  } else {
    return 'X';
  }
};

export const getCampCountdown = ({ group }) => {
  const day = group.day;
  const time = group.time;
  const timezone = group.timezone;
  const daysOfWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];
  const targetDayIndex = daysOfWeek.indexOf(day.toLowerCase());

  if (targetDayIndex === -1) {
    throw new Error("Invalid day. Must be a day of the week, e.g., 'Sunday'.");
  }

  // Current time in the specified timezone
  const now = new Date();
  const nowInTargetTimezone = new Date(
    now.toLocaleString('en-US', { timeZone: timezone })
  );

  // Parse the target time (hh:mm:ss)
  const [targetHour, targetMinute, targetSecond] = time.split(':').map(Number);

  // Calculate the target date
  const targetDate = new Date(nowInTargetTimezone);
  const currentDayIndex = targetDate.getDay();

  // Adjust to the target day of the week
  const dayDifference = (targetDayIndex - currentDayIndex + 7) % 7;
  targetDate.setDate(targetDate.getDate() + dayDifference);
  targetDate.setHours(targetHour, targetMinute, targetSecond, 0);

  // If the target time has already passed for today, move to the next week's target time
  if (targetDate <= nowInTargetTimezone) {
    targetDate.setDate(targetDate.getDate() + 7);
  }

  // Calculate the difference in milliseconds
  const diffMs = targetDate - nowInTargetTimezone;

  // Convert milliseconds to days, hours, and minutes
  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(
    (diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

  // Format the countdown
  const countdown = `${String(diffDays).padStart(2, '0')}d ${String(
    diffHours
  ).padStart(2, '0')}h ${String(diffMinutes).padStart(2, '0')}m`;

  return countdown;
};

export const getSpecialCampDate = (camp_date_time) => {
  const date = new Date(camp_date_time);

  // Format the date using toLocaleDateString
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  };
  const formattedDate = date.toLocaleDateString('en-GB', options);

  return formattedDate;
};