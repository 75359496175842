import React from 'react';
import { useHistory } from 'react-router-dom';
import { GUARDIAN_SETTINGS_ROUTE } from '../../../constants/routes.constants';
import { Container } from 'react-bootstrap';
import LoadButton from '../../common/LoadButton';
import EmptyHeaderWithoutSkip from '../../header/EmptyHeaderWithoutSkip';

const RedirectScreen = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push({
      pathname: GUARDIAN_SETTINGS_ROUTE,
      state: {
        from: 'kakao_login',
      },
    });
  };

  return (
    <div className="verify-account-container signup-container">
      <div className="guardian-login-container partner-signup-wrapper verify-account-wrapper">
        <EmptyHeaderWithoutSkip />
        <Container
          style={{ marginTop: '200px' }}
          className="onboarding-container"
          fluid="md"
        >
          <div className={`login-form-wrapper guardian-login redirect-screen1`}>
            <div class="guardian-ic-wrapper">
              <div class="guardian-ic">
                <img
                  src="https://storage.googleapis.com/tale-tree-4a1cfd33/static/b18cdf9e1f6b6bbea63129665262be35.svg"
                  alt="guardian"
                />
              </div>
            </div>

            <div className="title text-center">Congratulations</div>
            <div
              className="subTitle text-center kids-acc"
              style={{ fontWeight: 'bold' }}
            >
              {' '}
              You have created your TaleTree guardian account.{' '}
            </div>
            <div
              className="subTitle text-center"
              style={{ fontWeight: '600', fontSize: '18px' }}
            >
              {' '}
              The next step is to create your child's account!
            </div>
            <LoadButton
              text="Create child's account"
              className="login-btn p-2 gred-btn"
              onClick={handleClick}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default RedirectScreen;
