import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, Image } from 'react-bootstrap';
import { AiFillCheckCircle } from '@react-icons/all-files/ai/AiFillCheckCircle';
import { HiOutlineArrowRight } from '@react-icons/all-files/hi/HiOutlineArrowRight';
import RoundButton from './RoundButton';
import { KID_LOGIN_ROUTE, PAYMENT_ROUTE, VERIFICATION_PAYMENT_ROUTE } from '../../constants/routes.constants';
import skipArrow from '../../assets/icon/Arrow.svg';

import './scss/modal.scss';
import { useAuth } from "../auth/Auth";
import OnboardingModal from "../views/onboarding/OnboardingModal";
import { BsFillCheckCircleFill, BsVolumeUpFill } from "react-icons/bs";
import Cartoon from "../../assets/images/cartoon.svg";
import VoiceModulation from "../../assets/images/voice-modulation.svg";
import RocketUp from "../../assets/images/rocket-up.svg";
import { changeTextToVoice, getSoundWaveJson } from "../../utils/camp.utils";
import { customEvents } from "../../analytics/customEvents";
// import Lottie from "react-lottie-player";

const AccountVerification = forwardRef((props, ref) => {
  const history = useHistory();
  const { guardian, logout } = useAuth();
  // const dependentName = guardian.dependents[0].first_name;
  const dependentName = history?.location?.state?.dependent?.first_name;
  const [isOpen, setIsOpen] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [kidLoginModal, setKidLoginModal] = useState(
    history.location.state && history.location.state.from === "verified" &&
      guardian.dependents.length ? true : false
  );

  const closeModal = () => {
    let analyticsData = {
      category: "Kid Onboarding",
      action: "skip",
      label: 'skip'
    }
    customEvents(analyticsData);
    setIsOpen(false);
    if (history?.location?.state?.dependent?.profile?.is_activated) setKidLoginModal(true);
  };

  const handleClose = () => {
    let analyticsData = {
      category: "Kid Onboarding",
      action: "add another child",
      label: 'add another child'
    }
    customEvents(analyticsData);
    setKidLoginModal(false)

    history.replace({
      state: ''
    })
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setIsOpen(true);
    }
  }));

  const paymentPage = () => {
    let analyticsData = {
      category: "Kid Onboarding",
      action: "verify me now",
      label: 'verify me now'
    }
    customEvents(analyticsData);
    history.push({
      pathname: `${PAYMENT_ROUTE}`,
      state: {
        dependent: JSON.stringify(props?.dependent),
        type: 'activate',
      },
    })
  }

  const handleVoiceClick = () => {
    setIsPlay(!isPlay);
    const element = document.getElementById('voice-text')
    changeTextToVoice(element.textContent, isPlay, setIsPlay, isPaused, setIsPaused);
  }

  useEffect(() => {
    let analyticsData = {
      category: "Kid Onboarding",
      action: "safeguard kid",
      label: 'safeguard kid'
    }
    customEvents(analyticsData);
  }, [])

  useEffect(() => {
    if (kidLoginModal === true) {
      let analyticsData = {
        category: "Kid Onboarding",
        action: "confirmation kid account created",
        label: 'confirmation kid account created'
      }
      customEvents(analyticsData);
    }
  }, [kidLoginModal])

  const onGoToClick = () => {
    let analyticsData = {
      category: "Kid Onboarding",
      action: "go to taletree app button",
      label: 'go to taletree app button'
    }
    customEvents(analyticsData);
    logout(KID_LOGIN_ROUTE)
  }

  return (
    <>
      <Modal show={isOpen} className="signupPopup mobile-v-popup gardian auth-popup right-curved-div" centered>
        <Modal.Body>
          <div className='row bg-blue border-40'>
            <div className="col-md-6 col-lg-5 p-4 bg-blue border-left buble-relative overflow-hidden">
              <div className="voice-buble buuble-bounce" id="SpeechBubble" >
                <p id='voice-text' >Get verified now to enjoy the full TaleTree!</p>
              </div>
              <div className="text-left">
                {/* <p className="text-white voice-btn"><BsVolumeUpFill /> Voice on</p> */}
                <p className="" style={{ marginTop: '6rem' }}>

                </p>
              </div>
              <div className="cartoon-img">
                <img className="w-100" src={Cartoon} />
              </div>
            </div>
            <div className="col-md-6 col-lg-7 toparrow right-bottom-curve topinmius bg-white border-right right-curve auth-section white-right-arrow">
              <p className="auth-entry-text">Safeguard your child and enable them full access to TaleTree's interactive services:</p>
              <div>
                <ul className="list-ul">
                  <li><span className="index-count">01</span>Accept global and local challenges from TaleTree friends, partners and schools.</li>
                  <li><span className="index-count">02</span>Give and receive positively through commenting on other kids' creative ideas.</li>
                  <li><span className="index-count">03</span>Share and store your kids' creative projects and build their confidence.</li>
                </ul>
              </div>
              <div className="row w-100 mobile-btn-flex gardian-plan-btns-div" >
                <div className="col-12 col-md-12 col-sm-12">
                  <Button className=" grey-btn btn w-100" onClick={closeModal}>Skip</Button>
                </div>
                <div className="col-12 col-md-12 col-sm-12 mt-xl-3 mt-lg-3 mt-md-3 mt-sm-3 mt-3">
                  <Button className="gred-btn w-100 gardian-plan-btn" onClick={paymentPage}>Select a plan</Button>
                </div>
              </div>

            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="signupPopup mobile-v-popup gardian auth-popup right-curved-div account-veryfication-modal" onHide={handleClose} show={kidLoginModal} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="right-curved-div">
          <div className='row bg-blue border-40'>
            <div className="col-md-6 col-lg-5 p-4 bg-blue border-left buble-relative overflow-hidden">
              <div className="voice-buble buuble-bounce" id="SpeechBubble" >
                <p id='voice-text' >
                  It's time to meet the TaleTree Friends!
                </p>
              </div>
              <div className="text-left">
                {/* <p  className="text-white voice-btn" onClick={handleVoiceClick}><BsVolumeUpFill /> Voice on</p> */}
                <p className="" style={{ marginTop: '6rem' }}>

                </p>
              </div>
              <div className="cartoon-img">
                <img className="w-100" src={Cartoon} />
              </div>
            </div>
            <div className="col-md-6 col-lg-7 toparrow right-bottom-curve topinmius bg-white border-right right-curve text-center auth-section white-right-arrow">
              <div className="text-center">
                <img className="" src={RocketUp} />
              </div>
              <h3 className="modal-h3 mobile-modal-h3">
                Yay! <span> {dependentName}'s</span> all set to go.

              </h3>
              <Button className="gred-btn my-3" onClick={onGoToClick}>Go to Kid login</Button>
              <h5><a className="hyp-text text-center" href="javascript:void(0);" onClick={handleClose}>Return to dashboard</a></h5>
            </div>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
});

export default AccountVerification;
