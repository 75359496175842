import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl } from '../utils/config.utils';

const BASE_URL = getAPIUrl();
const SC = 'special_camps';
const DEPENDENTS = 'dependents';

const getTargetSpecialCamp = (id) => {
  return fetchUtils.newGet(
    `${BASE_URL}/${SC}/records${id ? `?camp_id=${id}` : ''}`
  );
};

const getSchoolSpecialCamp = (id) => {
  return fetchUtils.newGet(`${BASE_URL}/${SC}/records-camps`);
};

const getSpecialCamp = (params) => {
  const { id } = params;
  return fetchUtils.newGet(`${BASE_URL}/${SC}/${id}`);
};

const getSpecialCamps = (params) => {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.newGet(`${BASE_URL}/${SC}?${query}`);
};

const attendSpecialCamp = (params) => {
  return fetchUtils.post(`${BASE_URL}/${SC}/attendance`, params);
};

const getDependentSpecialCamp = (id) => {
  // return fetchUtils.newGet(`${BASE_URL}/${SC}?dependent_id=${id}&availability=upcoming`);
  return fetchUtils.newGet(`${BASE_URL}/${SC}/${id}`);
};

const getDependentMultiSpecialCamps = (dependent_id, query) => {
  const queryString = new URLSearchParams(query).toString();

  return fetchUtils.newGet(
    `${BASE_URL}/${DEPENDENTS}/specialcamp-accepted/${dependent_id}?${queryString}`
  );
};

const getDependentSpecialCampByCalendar = (id, params) => {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.newGet(`${BASE_URL}/${SC}/month_wise/${id}?${query}`);
};

const getGuardianDependentSpecialCamps = (campId, dependentId) => {
  return fetchUtils.newGet(
    `${BASE_URL}/${SC}/guardian-records${
      dependentId ? `?dependent_id=${dependentId}` : ''
    }`
  );
};

const getGuardianMultiSpecialCamps = (campId, dependent_id) => {
  return fetchUtils.newGet(
    `${BASE_URL}/${SC}/multi-records/?camp_id=${campId}&dependent_id=${dependent_id}`
  );
};

export const specialCampRepository = {
  getTargetSpecialCamp,
  getSpecialCamp,
  getSpecialCamps,
  attendSpecialCamp,
  getDependentSpecialCamp,
  getDependentMultiSpecialCamps,
  getSchoolSpecialCamp,
  getGuardianDependentSpecialCamps,
  getDependentSpecialCampByCalendar,
  getGuardianMultiSpecialCamps,
};
