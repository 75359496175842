import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import loaderJson from '../../../assets/json/Loader.json';
import { useLocation } from 'react-router-dom';
import { onboardingViewModel } from './onboarding.view.model';
import { useAuth } from '../../auth/Auth';
import { useHistory } from 'react-router-dom';
import {
  GUARDIAN_LOGIN_ROUTE,
  GUARDIAN_SETTINGS_ROUTE,
  HOME_FEED,
  MULTI_LOGIN,
  SSO_REDIRECT_SCREEN,
} from '../../../constants/routes.constants';

const MicrosoftCallBackScreen = () => {
    const location = useLocation();
    const [playLoader, setPlayLoader] = useState(true);
    const { setUser } = useAuth();
    const { state } = location
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    const error = searchParams.get('error');

    const handleLogin = async () => {
        try {
            const response = await onboardingViewModel.loginMicrosoftUser(code)
            if (response) {
                setUser(response);
                if (response?.dependents.length) {
                    history.push(MULTI_LOGIN);
                } else {
                    history.push(SSO_REDIRECT_SCREEN)
                }
            }
        } catch (error) {
            console.error('Error:', error);
            // history.goBack()
        }
    };

    const handleErrorRedirect = () => {
        let path;
        if(state?.from) {
            path = state?.from === 'login' ? GUARDIAN_LOGIN_ROUTE : HOME_FEED
        } else {
            path = GUARDIAN_LOGIN_ROUTE
        }

        history.push(path)
    }

    useEffect(() => {
        if (code) handleLogin();
    }, [code])

    useEffect(() => {
        if (error) handleErrorRedirect();
    }, [error])

    return (
        <div className="map-loader">
            <Lottie
                loop={true}
                play={playLoader}
                animationData={loaderJson}
                className="map-loader-in"
            />
        </div>
    )
}

export default MicrosoftCallBackScreen;